<template>
    <div>
        <div class="w-100 pl-2 pr-2">
            <Breadcrumb :breadcrumb="breadcrumbItems"/>
        </div>
        <List></List>
        <MetaTags 
            title="出品リスト｜ResMom相談online"
            description="リセマム相談オンラインの出品リストです。｜リセマム相談onlineは、日本最大級の教育情報Webメディア「リセマム」が提供する教育相談サービスです。幼児教育、小学校受験、中学受験、高校受験、大学受験、海外進学、国際教育、グローバル教育、進路相談、進路コンサル、留学、子育て相談や悩みについて専門家や先輩保護者などに「すぐに」「気軽に」「どこでも」相談いただけます。"
        />
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import List from './components/list.vue';
export default {
    name: "Lesson",
    components:{
        List
    },
    data() {
        return {
            breadcrumbItems: [
                {
                    title: this.$t("menu.breadcrumb.my_page"),
                    link: "/my-page"
                },
                {
                    title: this.$t("menu.breadcrumb.my-page.lesson"),
                    link: "#"
                }
            ]
        }
    },
    async created(){
        await this.getUserInfo();
        if(!this.user.teacher 
            || this.user.teacher.status != "active"){
                window.location.href = "/top/apply-to-teacher";
            }
    },
    computed:{
        ...mapGetters({
            user: "member/getUser"
        })
    },
    methods: {
        ...mapActions({
            getUserInfo: "member/getUserInfo",
        })
    }
}
</script>
<style lang="">
    
</style>