<template>
    <div :class="`card ${item.status}`">
        <div class="card-head  d-flex justify-content-between">
            <div class="card-title">
                {{
                    item.status == 'active' 
                    ? $t("lesson.form.available") 
                    : $t("lesson.form.no_available") 
                }}
            </div>
            <router-link 
                :to="{name: 'my-page/lesson-edit', params: {lessonId: item.id}}" 
                class="btn btn-sm btn-edit"
                >{{$t("lesson.to_edit")}}</router-link>
            
        </div>
        <div class="card-body" id="table">
            <div class="tr">
                <div class="td">
                    <div class="cimg mr-3 mb-3">
                        <img :src="item.thumbnail" :alt="item.title"/>
                    </div>
                </div>
                <div class="td w-100">
                    <div class="card-text">
                        <div class="card-info" v-if="item.level">{{$t(`lesson.${item.level}`)}}</div>
                        <div class="card-price">{{formatCurrency(item.currency_code, item.price)}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body" id="table">
            <div class="tr">
                <div class="td w-100">
                    <h5 class="card-title">{{item.title}}</h5>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "Item",
    props: {
        item: {
            type: Object,
            default: () => {}
        }
    },
    methods: {
        _formatTime(start, end) {
            return (
                this.dateInstance(start).format("HH:mm") +
                " - " +
                this.dateInstance(end).format("HH:mm")
            );
        },
    },
}
</script>