<template>
    <div class="text-center">
        <p>{{$t("lesson.lesson_no_selling")}}</p>
        <router-link 
                :to="{name: 'my-page/lesson-create'}" 
                class="btn btn-add"
                >{{$t("lesson.sell")}}</router-link>
    </div>
</template>
<script>
export default {
    "name": "Empty"
}
</script>